import { clone, isParsable, round } from 'shared/utils';
import { add, sub, mul, div, gtz, ltz, gte, lte } from 'shared/big';

import { WORK_PROPS } from '../const';

import Calc from './Calc';
import { rectifyRooms } from './rectifyRooms';
import { rectifyResult } from './rectifyResult';
import { findByIdOrUuid } from './findByIdOrUuid';

class CalcAct extends Calc {
  constructor(
    deal,
    outlay,
    workTypes,
    materialTypes,
    outlayVariables,
    discountConstraints,
    actNumber = -2
  ) {
    super(deal, outlay, workTypes, materialTypes, outlayVariables, discountConstraints);
    this.actNumber = actNumber;
    this.isAllActs = this.actNumber === -2;
    this.subActs = this.outlay.subActs.sort((a, b) => +a.actNumber - +b.actNumber);
    // this.isFinal = false;
  }

  calculateAll() {
    for (const room of this.rooms) {
      this.calcRoomProps(room);
      for (const roomPosition of room.roomPositions) {
        for (const prop of WORK_PROPS) this.calcWork(roomPosition, prop);

        if (+roomPosition.actNumber !== -2) continue;

        roomPosition.actQuantityTotal = roomPosition.actQuantities.reduce(
          (total, quantity, actNumber) => {
            return actNumber === 0 ? total : add(total, quantity);
          },
          0
        );

        const maxQuantity = round(
          roomPosition.quantityManual !== null
            ? roomPosition.quantityManual
            : roomPosition.quantityCalculated
        );
        const surrogateWork = room.roomPositions.find(
          (work) => work.uuid === `FAKE-${roomPosition.uuid}` && +work.actNumber === 0
        );
        surrogateWork.quantityManual = gte(roomPosition.actQuantityTotal, maxQuantity)
          ? 0
          : sub(maxQuantity, roomPosition.actQuantityTotal);
        roomPosition.actQuantities[0] = surrogateWork.quantityManual;

        roomPosition.actSums = roomPosition.actQuantities.map((quantity) => {
          return mul(roomPosition.priceCalculated, quantity);
        });
        roomPosition.actSumTotal = roomPosition.actSums.reduce((total, sum, actNumber) => {
          return actNumber === 0 ? total : add(total, sum);
        }, 0);
      }
    }

    for (const room of this.rooms) this.calculateRoomSum(room);

    this.finishCalculations();

    this.clearCalcedFlags();
  }

  _updatePosValue(workId, roomId, prop, newVal) {
    const room = this.rooms.find(findByIdOrUuid(roomId));
    const workIdx = room.roomPositions.findIndex(findByIdOrUuid(workId));
    const work = room.roomPositions[workIdx];

    if (prop === 'actQuantities') {
      work.actQuantityTotal = newVal.reduce((total, quantity, actNumber) => {
        return actNumber === 0 ? total : add(total, quantity);
      }, 0);
      const workQuantity = round(
        work.quantityManual !== null ? work.quantityManual : work.quantityCalculated
      );
      newVal[0] = lte(workQuantity, work.actQuantityTotal)
        ? 0
        : sub(workQuantity, work.actQuantityTotal);

      work.actQuantities = newVal;
      work.actSums = newVal.map((quantity) => {
        return mul(work.priceCalculated, quantity);
      });
      work.actSumTotal = work.actSums.reduce((total, sum, actNumber) => {
        return actNumber === 0 ? total : add(total, sum);
      }, 0);

      for (let idx = 0; idx < work.actQuantities.length; idx++) {
        const quantity = work.actQuantities[idx];
        const surrogateWork = room.roomPositions[workIdx + idx + 1];
        surrogateWork.quantity = quantity;
        surrogateWork.quantityManual = surrogateWork.quantity;

        for (const prop of WORK_PROPS) {
          this.calcWork(surrogateWork, prop);
        }
      }
    } else if (prop === 'actNumber') {
      if (newVal === -2) {
        room.roomPositions.splice(
          workIdx + 1,
          0,
          ...work.actQuantities.map((quantity, actNumber) => ({
            ...work,
            id: `FAKE-${work.id}`,
            uuid: `FAKE-${work.uuid}`,
            actNumber,
            quantity,
            quantityManual: quantity,
            isSurrogate: true,
          }))
        );

        work.actNumber = newVal;
        this.genDepTree();
        // this.compileFormulas();

        return this._updatePosValue(workId, roomId, 'actQuantities', work.actQuantities);
      } else if (work.actNumber === -2) {
        const fakeUuid = `FAKE-${work.uuid}`;
        room.roomPositions = room.roomPositions.filter(
          (filteredWork) => filteredWork.uuid !== fakeUuid
        );
      }

      work.actNumber = newVal;

      // if (work.custom) {
      //   this.calcPos(work, 'sum', true);

      // } else {
      for (const prop of WORK_PROPS) {
        this.updatePositionChildren(work, roomId, prop);
      }
      // }
    } else {
      // if (work.custom) {
      // work[prop + 'Manual'] = newVal;
      // this.calcPos(work, 'sum', true);

      // } else {
      work[prop + 'Manual'] = newVal;
      this.calcWork(work, prop, true);
      this.updatePositionChildren(work, roomId, prop);
      // }
    }

    this.calculateRoomSum(room);
  }

  updateOutlayValue(prop, newVal) {
    if (prop) this.outlay[prop] = isParsable(newVal) ? round(newVal) : newVal;
    if (prop === 'subActs') {
      this.subActs = this.subActs.map((subAct) => ({
        ...subAct,
        ...newVal[subAct.actNumber],
      }));
    }
    if (prop === 'VAT') {
      this.outlay.VAT = newVal;
      this.isVAT = newVal;
    }

    this.updateOutlayChildren(prop);

    for (const room of this.rooms) {
      this.calculateRoomSum(room);
    }

    this.finishCalculations();
  }

  checkTechDisclaimer() {
    for (const room of this.rooms) {
      const disclaimedWorks = room.roomPositions.filter(
        (work) => work.technologyDisclaimerCalculated
      );
      if (disclaimedWorks.length === 0) continue;

      this.technologyDisclaimer = true;
      for (const work of disclaimedWorks) {
        const actNumber = +work.actNumber;
        const act = this.subActs[actNumber];
        if (!act) continue;
        act.technologyDisclaimer = true;
      }
    }
  }

  updateActNumber(actNumber, force = true) {
    if (this.isOutlay) return;
    this.actNumber = +actNumber;
    this.isAllActs = this.actNumber === -2;

    if (force) this.calculateAll();
  }

  updateSubActId(actNumber, newId) {
    if (this.isOutlay) return;
    if (actNumber === -2) return;
    const act = this.subActs[actNumber];
    if (!act) return;
    act.id = newId;
  }

  updateSubAct(actNumber, data) {
    if (this.isOutlay) return;
    this.subActs = this.subActs.map((subAct) => {
      if (+subAct.actNumber !== +actNumber) return subAct;
      return { ...subAct, ...data };
    });
    this.calculateAll();
  }

  positionSumReducer(positions, actNumber) {
    let posReducer = (total, work) => {
      if (work.isTurnedOff) return total;
      const workActNumber = +work.actNumber;
      if (workActNumber === -2) return total;
      if (workActNumber !== actNumber) return total;
      return add(total, work.sumCalculated);
    };

    if (actNumber === undefined) {
      if (this.isAllActs)
        posReducer = (total, work) => {
          if (work.isTurnedOff) return total;
          const workActNumber = +work.actNumber;
          if (workActNumber === -2) return total;
          if (workActNumber === 0) return total;
          return add(total, work.sumCalculated);
        };
      else
        posReducer = (total, work) => {
          if (work.isTurnedOff) return total;
          const workActNumber = +work.actNumber;
          if (workActNumber === -2) return total;
          if (workActNumber !== this.actNumber) return total;
          return add(total, work.sumCalculated);
        };
    }

    return positions.reduce(posReducer, 0);
  }

  calcSum() {
    this.sum = 0;
    this.fullSum = 0;
    this.discountableSum = 0;
    this.subActs = this.subActs.map((act) => ({
      ...act,
      sum: 0,
      fullSum: 0,
      discount: 0,
      promotionSum: 0,
      promotionPercent: 0,
      totalDiscount: 0,
      totalDiscountValue: 0,
      categorySumNP: 0,
      categorySumSpecial: 0,
      categorySumCleaning: 0,
      masterWageSum: 0,
      masterWageSumSpecial: 0,
      masterWageSumNP: 0,
      masterWageSumCleaning: 0,
      promotionSumNP: 0,
      promotionSumCleaning: 0,
      total: {
        sumDismantling: 0,
        sumRough: 0,
        sumClean: 0,
        sumWiring: 0,
        sumPlumbing: 0,
        sumPrep: 0,
        sumNP: 0,
        sumSpecial: 0,
        sumDoors: 0,
        sumCleaning: 0,
        masterWageNP: 0,
        masterWageSpecial: 0,
        masterWageDoors: 0,
        masterWageCleaning: 0,
      },
    }));
    this.workTypeSums = { 0: 0 };
    for (const type of this.workTypes) this.workTypeSums[type.id] = 0;

    for (const room of this.rooms) {
      for (const work of room.roomPositions) {
        if (work.isTurnedOff) continue;
        const workActNumber = +work.actNumber;
        const sum = work.sumCalculated;
        const masterWage = work.masterWageCalculated;

        const workTypeId = work.customWorkTypeId ?? work.position?.workTypeId ?? 0;
        const workType = this.workTypes.find(({ id }) => id === workTypeId);

        if (workActNumber !== -2) {
          const act = this.subActs[workActNumber];

          if (act) {
            act.sum = add(act.sum, sum);
            act.fullSum = act.sum;
            act.masterWageSum = add(act.masterWageSum, masterWage);

            switch (workType?.rid) {
              case 1: // Dismantling
                act.total.sumDismantling = add(act.total.sumDismantling, sum);
                break;
              case 2: // Rough
                act.total.sumRough = add(act.total.sumRough, sum);
                break;
              case 3: // Clean
                act.total.sumClean = add(act.total.sumClean, sum);
                break;
              case 4: // Wiring
                act.total.sumWiring = add(act.total.sumWiring, sum);
                break;
              case 5: // Plumbing
                act.total.sumPlumbing = add(act.total.sumPlumbing, sum);
                break;
              case 6: // Prep
                act.total.sumPrep = add(act.total.sumPrep, sum);
                break;
              case 7: // NP
                act.total.sumNP = act.categorySumNP = add(act.categorySumNP, sum);
                act.total.masterWageNP = act.masterWageSumNP = add(act.masterWageSumNP, masterWage);
                break;
              case 8: // Special
                act.total.sumSpecial = act.categorySumSpecial = add(act.categorySumSpecial, sum);
                act.total.masterWageSpecial = act.masterWageSumSpecial = add(act.masterWageSumSpecial, masterWage);

                const matTypeId = work.customMaterialTypeId ?? work.position?.materialTypeId;
                if (matTypeId && matTypeId === this.doorMatTypeId) {
                  act.total.sumDoors = act.categorySumDoors = add(act.categorySumDoors, sum);
                  act.total.masterWageDoors = act.masterWageSumDoors = add(act.masterWageSumDoors, masterWage);
                }
                break;
              case 9: // Cleaning
                act.total.sumCleaning = act.categorySumCleaning = add(act.categorySumCleaning, sum);
                act.total.masterWageCleaning = act.masterWageSumCleaning = add(act.masterWageSumCleaning, masterWage);
                break;
            }
          }
        }

        if (workActNumber === 0 || workActNumber === -2) continue;

        this.sum = add(this.sum, sum);
        this.fullSum = this.sum;
        this.workTypeSums[workTypeId] = add(this.workTypeSums[workTypeId], sum);

        if (!work.hasDiscount) continue;
        this.discountableSum = add(this.discountableSum, sum);
      }
    }

    this.maxDiscount = this.outlay.maxDiscount;
    this.maxDiscountValue = mul(this.discountableSum, this.maxDiscount).div(100).toScale(2);

    this.promotionSum = 0;
    for (const promo of this.outlay.outlayPromotions) {
      const actNumber = +promo.actNumber;
      if (actNumber === 0) continue;

      const act = this.subActs[actNumber];
      if (!act) continue;

      const promoSum = promo.promotion.value ?? promo.valueManual;
      act.promotionSum = add(act.promotionSum, promoSum);
      this.promotionSum = add(this.promotionSum, promoSum);

      if (promo.promotion.rid === 4) {
        this.promotionSumNP = add(this.promotionSumNP ?? 0, promoSum);
        act.promotionSumNP = add(act.promotionSumNP ?? 0, promoSum);
      }
      if (promo.promotion.rid === 9) {
        this.promotionSumCleaning = add(this.promotionSumCleaning ?? 0, promoSum);
        act.promotionSumCleaning = add(act.promotionSumCleaning ?? 0, promoSum);
      }
    }

    const isDiscSumGtz = gtz(this.discountableSum);

    this.promotionPercent = isDiscSumGtz
      ? div(this.promotionSum, this.discountableSum).mul(100)
      : 0;

    this.discountValue = 0;
    for (const act of this.subActs) {
      // act.discountValue = act.discountValue;
      act.discount = isDiscSumGtz
        ? div(act.discountValue, this.discountableSum).mul(100).toScale(2)
        : 0;
      if (+act.actNumber === 0) {
        act.discountValue = 0;
        act.discount = 0;
        act.isDiscountValid = true;
      }
      act.promotionPercent = isDiscSumGtz
        ? div(act.promotionSum, this.discountableSum).mul(100).toScale(2)
        : 0;
      act.totalDiscountValue = add(act.discountValue, act.promotionSum);
      act.totalDiscount = add(act.discount, act.promotionPercent);
      act.isDiscountValid = gte(act.sum, act.discountValue);

      if (+act.actNumber === 0) continue;
      this.discountValue = add(this.discountValue, act.discountValue);
    }
    this.discount = 0;
    this.discountPercent = isDiscSumGtz
      ? div(this.discountValue, this.discountableSum).mul(100).toScale(2)
      : 0;

    this.totalDiscountValue = add(this.discountValue, this.promotionSum);
    this.totalDiscount = add(this.discountPercent, this.promotionPercent);

    this.remainingDiscountValue = sub(this.maxDiscountValue, this.discountValue);
    this.remainingDiscount = sub(this.maxDiscount, this.discountPercent);
    this.isDiscountValid =
      !ltz(this.remainingDiscountValue) && !this.subActs.some((act) => !act.isDiscountValid);

    if (!this.isDiscountValid) this.isFinal = false;
    this.showDiscount = false;

    this.workTypeConstraintViolations = [];
    for (const type of this.workTypes) {
      const workTypeSum = round(this.workTypeSums[type.id]);

      switch (type.rid) {
        case 7:
          this.categorySumNP = workTypeSum;
          break;
        case 8:
          this.categorySumSpecial = workTypeSum;
          break;
        case 9:
          this.categorySumCleaning = workTypeSum;
          break;
      }

      if (type.minimalSum === null || eqz(workTypeSum)) continue;

      const minimalSum = round(type.minimalSum);
      if (gte(workTypeSum, minimalSum)) continue;

      this.workTypeConstraintViolations.push(type.name);
    }
  }

  _finishCalculations() {
    this.calcSum();
    this.checkTechDisclaimer();

    this.rectifiedRooms = rectifyRooms(
      this.rooms,
      this.workTypes,
      this.materialTypes,
      this.actNumber
    );
    this.result = rectifyResult(this, this.rooms, 'act', this.subActs);
  }
}

export default CalcAct;
