import { isParsable, round } from 'shared/utils';
import { add, sub, mul, div, gtz, ltz, lt, eqz } from 'shared/big';

import { WORK_PROPS } from '../const';

import Calc from './Calc';
import { rectifyRooms } from './rectifyRooms';
import { rectifyResult } from './rectifyResult';
import { findByIdOrUuid } from './findByIdOrUuid';

class CalcOutlay extends Calc {
  _updatePosValue(roomPositionId, roomId, prop, newVal) {
    const room = this.rooms.find(findByIdOrUuid(roomId));
    const roomPosition = room.roomPositions.find(findByIdOrUuid(roomPositionId));

    if (prop === 'isTurnedOff') {
      roomPosition[prop] = newVal;
      // if (roomPosition.custom) {
      //   if (newVal === true) {
      //     roomPosition.sumCalculated = 0;
      //   } else if (newVal === false) {
      //     this.calcPos(roomPosition, 'sum', true);
      //   }
      // } else {
      for (const workProp of WORK_PROPS)
        this.updatePositionChildren(roomPosition, roomId, workProp);
      // }
    } else {
      roomPosition[prop + 'Manual'] = newVal;
      if (roomPosition.custom) {
        for (const workProp of WORK_PROPS)
          this.calcWork(roomPosition, workProp, true);

      } else {
        this.calcWork(roomPosition, prop, true);
      }

      this.updatePositionChildren(roomPosition, roomId, prop);
    }

    this.calculateRoomSum(room);
  }

  updateOutlayValue(prop, newVal) {
    if (prop) this.outlay[prop] = isParsable(newVal) ? round(newVal) : newVal;
    if (prop === 'VAT') {
      this.outlay.VAT = newVal;
      this.isVAT = newVal;
    }

    this.updateOutlayChildren(prop);

    for (const room of this.rooms) {
      this.calculateRoomSum(room);
    }

    this.finishCalculations();
  }

  checkTechDisclaimer() {
    this.technologyDisclaimer = this.rooms.some(({ roomPositions }) =>
      roomPositions.some((work) => work.technologyDisclaimerCalculated)
    );
  }

  positionSumReducer(positions) {
    return positions.reduce(
      (total, work) => (work.isTurnedOff ? total : add(total, work.sumCalculated)),
      0
    );
  }

  calcSum() {
    this.sum = 0;
    this.fullSum = 0;
    this.discountableSum = 0;
    this.discountValue = 0;
    this.workTypeSums = { '0': 0 };
    this.total = {
      
      sumDismantling: 0,
      sumRough: 0,
      sumClean: 0,
      sumWiring: 0,
      sumPlumbing: 0,
      sumPrep: 0,
      sumNP: 0,
      sumSpecial: 0,
      sumDoors: 0,
      sumCleaning: 0,
      masterWageNP: 0,
      masterWageSpecial: 0,
      masterWageDoors: 0,
      masterWageCleaning: 0,
    };
    for (const type of this.workTypes) {
      this.workTypeSums[type.id.toString()] = 0;
    }

    for (let idx = 0; idx < this.rooms.length; idx++) {
      const room = this.rooms[idx];
      for (let ydx = 0; ydx < room.roomPositions.length; ydx++) {
        const work = room.roomPositions[ydx];
        if (work.isTurnedOff) continue;

        const sum = work.sumCalculated;
        const workTypeId = work.position?.workTypeId?.toString() || '0';
        const workType = this.workTypes.find(({ id }) => id === workTypeId);

        switch (workType?.rid) {
          case 1: // Dismantling
            this.total.sumDismantling = add(this.total.sumDismantling, sum);
            break;
          case 2: // Rough
            this.total.sumRough = add(this.total.sumRough, sum);
            break;
          case 3: // Clean
            this.total.sumClean = add(this.total.sumClean, sum);
            break;
          case 4: // Wiring
            this.total.sumWiring = add(this.total.sumWiring, sum);
            break;
          case 5: // Plumbing
            this.total.sumPlumbing = add(this.total.sumPlumbing, sum);
            break;
          case 6: // Prep
            this.total.sumPrep = add(this.total.sumPrep, sum);
            break;
          case 7: // NP
            this.total.sumNP = this.categorySumNP = add(this.categorySumNP, sum);
            this.total.masterWageNP = this.masterWageSumNP = add(this.masterWageSumNP, masterWage);
            break;
          case 8: // Special
            this.total.sumSpecial = this.categorySumSpecial = add(this.categorySumSpecial, sum);
            this.total.masterWageSpecial = this.masterWageSumSpecial = add(this.masterWageSumSpecial, masterWage);

            const matTypeId = work.customMaterialTypeId ?? work.position?.materialTypeId;
            if (matTypeId && matTypeId === this.doorMatTypeId) {
              this.total.sumDoors = this.categorySumDoors = add(this.categorySumDoors, sum);
              this.total.masterWageDoors = this.masterWageSumDoors = add(this.masterWageSumDoors, masterWage);
            }
            break;
          case 9: // Cleaning
            this.total.sumCleaning = this.categorySumCleaning = add(this.categorySumCleaning, sum);
            this.total.masterWageCleaning = this.masterWageSumCleaning = add(this.masterWageSumCleaning, masterWage);
            break;
        }

        this.sum = add(this.sum, sum);
        const workTypeKey = work.position?.workTypeId?.toString() || '0';
        this.workTypeSums[workTypeKey] = add(this.workTypeSums[workTypeKey], sum);
        const fullSum = work.fullSumCalculated
        this.fullSum = add(this.fullSum, fullSum);
        this.discountValue = add(this.discountValue, work.discountSumCalculated);

        if (!work.hasDiscount) continue;
        this.discountableSum = add(this.discountableSum, fullSum);
      }
    }

    this.discount = round(this.outlay.discount);

    if (isParsable(this.maxDiscountOverride)) {
      this.maxDiscount = round(this.maxDiscountOverride);
    } else {
      this.maxDiscount = 0;
      for (const constraint of this.discountConstraints) {
        if (lt(this.discountableSum, constraint.sum)) continue;
        this.maxDiscount = round(constraint.discount);
      }
    }

    this.maxDiscountValue = mul(this.discountableSum, this.maxDiscount).div(100).toScale(2);

    this.promotionSum = 0;
    for (const promo of this.outlay.outlayPromotions) {
      const promoSum = round(promo.promotion.value || promo.valueManual);

      if (promo.promotion.rid === 4)
        this.promotionSumNP = add(this.promotionSumNP ?? 0, promoSum);
      if (promo.promotion.rid === 9)
        this.promotionSumCleaning = add(this.promotionSumCleaning ?? 0, promoSum);

      this.promotionSum = add(this.promotionSum, promoSum);
    }
    const isDiscountableSumNonZero = gtz(this.discountableSum);
    this.promotionPercent = isDiscountableSumNonZero
      ? div(this.promotionSum, this.discountableSum).mul(100).toScale(2)
      // ? div(this.promotionSum, this.discountableSum).mul(100)
      : 0;

    // this.totalDiscountValue = add(this.discountValue, this.promotionSum);
    this.totalDiscount = add(this.discount, this.promotionPercent);
    this.totalDiscountValue = mul(this.discountableSum, this.totalDiscount).div(100).toScale(2);
    // this.totalDiscount = isDiscountableSumNonZero ? div(this.totalDiscountValue, this.discountableSum).mul(100).toScale(2) : 0;


    // this.maxDiscountValue = mul(this.discountableSum, this.maxDiscount).div(100).toScale(2);

    // this.remainingDiscountValue = sub(this.maxDiscountValue, this.totalDiscountValue);
    this.remainingDiscount = sub(this.maxDiscount, this.totalDiscount);
    this.remainingDiscountValue = mul(this.discountableSum, this.remainingDiscount).div(100).toScale(2);
    // this.remainingDiscount = isDiscountableSumNonZero ? div(this.remainingDiscountValue, this.discountableSum).mul(100).toScale(2) : 0;
    this.isDiscountValid = !ltz(this.remainingDiscountValue);
    if (!this.isDiscountValid) this.isFinal = false;
    this.showDiscount = gtz(this.discount);

    // this.areWorkTypeConstraintsMet = true;
    this.workTypeConstraintViolations = [];
    for (const type of this.workTypes) {
      const workTypeSum = round(this.workTypeSums[type.id.toString()]);

      switch (type.id) {
        case 7:
          this.categorySumNP = workTypeSum;
          break;
        case 8:
          this.categorySumSpecial = workTypeSum;
          break;
        case 9:
          this.categorySumCleaning = workTypeSum;
          break;
      }

      if (type.minimalSum === null) continue;
      if (eqz(workTypeSum)) continue;

      const minimalSum = round(type.minimalSum);
      if (!lt(workTypeSum, minimalSum)) continue;

      // this.areWorkTypeConstraintsMet = false;
      this.workTypeConstraintViolations.push(type.name);
    }
  }

  _finishCalculations() {
    this.calcSum();
    this.checkTechDisclaimer();

    this.rectifiedRooms = rectifyRooms(this.rooms, this.workTypes, this.materialTypes);
    this.result = rectifyResult(this, this.rooms);
  }
}

export default CalcOutlay;
